import React, { useContext } from 'react'
import './HeroBigAsset.scss'
import Wave from '../../images/icons/Wave'
import { HeaderContext } from '../Header/Header'

export const isVideo = (item) => {
	const allWpVideoTypes = [
		'.MP4',
		'.M4V',
		'.MOV',
		'.WMV',
		'.AVI',
		'.MPG',
		'.OGV',
		'.3GP',
		'.3G2',
	]

	if (item) {
		return allWpVideoTypes.some((type) => {
			const _item = item.toLowerCase()
			const _type = type.toLowerCase()
			return _item.includes(_type)
		})
	} else {
		return false
	}
}

export default (props) => {
	const { image, video, text, course } = props
	const HeaderCTX = useContext(HeaderContext)

	const data = {
		images: {
			small: image.small ? image.small : null,
			// : '../../images/inbalans-image-9.jpg',
			large: image.large ? image.large : null,
			// : '../../images/inbalans-image-9.jpg',
			alt: image.alt ? image.alt : null,
			// 'Schaatsreis in Zweden',
		},
		video: video ? video : null,
		// : 'https://interactive-examples.mdn.mozilla.net/media/examples/flower.webm',
		text: text ? text : null,
		// : 'Schaatsen op prachtige natuurijs in een schitterende omgeving van bossen, akkers en de pittoreske rode Zweedse landhuisjes',
	}

	return (
		<div className={`hero-asset__wrapper${course ? ' course' : ''}`}>
			<section className="hero-asset">
				{(data.video && data.video !== null) ||
				isVideo(data.images.small) ? (
					<video
						controls={true}
						preload={'metadata'}
						autoPlay={false}
						loop={false}
						src={data.images.small}
					/>
				) : (
					<picture>
						<source
							srcSet={data.images.large}
							media="(min-width: 768px)"
						/>
						<source srcSet={data.images.small} />
						<img
							src="https://via.placeholder.com/1020x720"
							alt=""
						/>
					</picture>
				)}
			</section>
			{data.text && data.text !== null ? (
				<article className="hero-asset__incentive">
					<p>{data.text}</p>
					<Wave
						winter={HeaderCTX.winterTime}
						color={
							HeaderCTX.winterTime
								? '#005394'
								: 'rgba(234, 134, 45, 1)'
						}
					/>
				</article>
			) : null}
		</div>
	)
}
