import React from 'react'
import Slider from 'react-slick'
import './FullWidthImageCarousel.scss'
import { useMediaQuery } from 'react-responsive'
import { isVideo } from '../HeroBigAsset/HeroBigAsset'

export default ({ className, images }) => {
	const isMobile = useMediaQuery({
		query: '(max-width: 48rem)',
	})

	function SamplePrevArrow(props) {
		const { className, onClick } = props
		return (
			<button className={className} onClick={onClick}>
				Vorige
			</button>
		)
	}

	function SampleNextArrow(props) {
		const { className, onClick } = props
		return (
			<button className={className} onClick={onClick}>
				Volgende
			</button>
		)
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isMobile ? 1 : 3,
		slidesToScroll: 1,
		className: className,
		cssEase: 'cubic-bezier(.68,.63,.2,1.35)',
		arrows: true,
		prevArrow: <SamplePrevArrow />,
		nextArrow: <SampleNextArrow />,
	}

	return (
		<Slider {...settings}>
			{images.length
				? images.map((image, i) => {
						return (
							<figure
								className="full-width__image-carousel__image-holder"
								key={
									image.alt
										? image.alt
										: image.alt_text
										? image.alt_text
										: i
								}
							>
								{isVideo(
									image.src ? image.src : image.source_url,
								) ? (
									<video
										className="full-width__image-carousel__image"
										controls={true}
										preload={'metadata'}
										autoPlay={false}
										loop={false}
										src={
											image.src
												? image.src
												: image.source_url
										}
									/>
								) : (
									<img
										className="full-width__image-carousel__image"
										src={
											image.src
												? image.src
												: image.source_url
												? image.source_url
												: ''
										}
										alt={
											image.alt
												? image.alt
												: image.alt_text
												? image.alt_text
												: ''
										}
									/>
								)}
							</figure>
						)
				  })
				: null}
		</Slider>
	)
}
